<script lang="ts">
  import { onMount } from "svelte";
  import Card from "./CountdownCard.svelte";

  export let date="2023-01-01 00:00:00";

  let time = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  // Set the target 9 days from the current time
  let d=date.split(".");
  let target;
  if(d.length>1) {
      let dh=d[2].split(" ");
      target = new Date(dh[0]+"-"+d[1]+"-"+d[0]+" "+dh[1]);
  } else { target = new Date(date); }

  const handleTick = () => {
    const currentDate = new Date();
    const gap = target.getTime() - currentDate.getTime();
    const getDays = Math.floor((gap / (1000 * 60 * 60 * 24)));
    const getHours = Math.floor((gap / (1000 * 60 * 60)) % 24);
    const getMinutes = Math.floor((gap / 1000 / 60) % 60);
    const getSeconds = Math.floor((gap / 1000) % 60);

    time.days = getDays;
    time.hours = getHours;
    time.minutes = getMinutes;
    time.seconds = getSeconds;
  };
  onMount(() => {
    handleTick()
  });
</script>

{#if time.minutes>=0}
<div class="countdown">
  <Card callback={handleTick} name="Dny" number={time.days} />
  <Card callback={handleTick} name="Hodiny" number={time.hours} />
  <Card callback={handleTick} name="Minuty" number={time.minutes} />
  <Card callback={handleTick} name="Vteřiny" number={time.seconds} />
</div>
{/if}

<style>
  .countdown {
    display: flex;
    justify-content: center;
    gap: .5rem;
    margin-bottom:-80px;
  }
  @media (min-width: 768px) {
    h1 {
      letter-spacing: 9px;
    }
    .countdown {
      gap: 5rem 2.5rem;
      flex-wrap: wrap;
    }
  }
</style>
