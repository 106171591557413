<script>
  import Link from "./linkItem.svelte";
  import { Icon } from 'sveltestrap';
  import MediaQuery from "./MediaQuery.svelte";

  export let to="/";
  export let icon=false;
</script>

<MediaQuery query="(max-width: 440px)" let:matches>
  {#if matches}
      {#if icon}
        <span class="menuLinkMobile">
          <Link to={to}><Icon name={icon} /></Link>&nbsp;
        </span>
      {:else}
        <span class="menuLink">
          <Link to={to}><span class="link"><slot /></span></Link>
        </span>
      {/if}
  {:else}
    <span class="menuLink">
      {#if icon}
        <Link to={to}><Icon name={icon} /></Link>&nbsp;
      {/if}
      <Link to={to}><span class="link"><slot /></span></Link>
    </span>
  {/if}
</MediaQuery>

<style>
  .menuLink { font-size:120%; }
  .menuLinkMobile { font-size:155%; }
  .link { text-decoration:underline; }
  .link:hover { text-decoration:none; }
</style>
