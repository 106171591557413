<script>
  import {
		Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardSubtitle,
    CardText,
    CardTitle,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
    } from 'sveltestrap';
  import { api_url, path } from "../ConfigService.js";

  export let id;
  export let key;
  export let data=false;
  export let open=false;
  export let selected=[];
  export let variant=[];
  export let selectedFile=false;
  export let selectedFileId=false;
  export let selectedSkupinova="0";
  export let addToCartCount=0;
  const toggle = () => (selectedFile = !selectedFile);

  $: {
    if(selectedFile) {
        open=true;
        selected=[];
    		variant=[];
    } else {
        open=false;
        selected=[];
    		variant=[];
    }
  }

	async function addToCart() {
		const resp = await fetch($api_url+'?addToCart=true&album='+id+'&key='+key, {
			method: 'POST',
			body: JSON.stringify({
        file: selectedFile,
				id: selectedFileId,
				variant: variant,
				selected: selected
			})
		})
    data = await resp.json();
    if(await data) {
        if(await data.cart) { addToCartCount=await data.cart.length; }
        else { addToCartCount=0; }
    }
    closeSelect();
	}

	function newVariant(index, value, cart) {
		//uloží ID varianty pro přidání do košíku
		variant[index]=parseInt(value);
		//načte zvolený počet z košíku
    if(cart && Array.isArray(cart)) {
  		for(let i=0; i<cart.length; i++) {
        if(cart[i].id==selectedFileId) {
    			if(parseInt(cart[i].type)==parseInt(value) && typeof selected[index] === 'undefined') { selected[index]=cart[i].count; }
        }
  		}
    }
		return "";
	}
	function closeSelect() { selectedFile=false; selectedFileId=false; open=false; }
  function getPrice(i, cena, min=1) { return ((i+1+pocetAdd(min))*parseInt(cena)); }
  function setValue(z, value) { selected[z]=value; }
  function pocetCount(minimalni, maximalni) {
    return parseInt(maximalni)-pocetAdd(minimalni);
  }
  function pocetAdd(minimalni) {
    let min=parseInt(minimalni);
    if(min && min>1) { return (min-1); }
    return 0;
  }
  function setItem(d, z, count) {
    selected[z]=count;
    if(d.variant.length==1) { addToCart(); }
  }
</script>
{#await data}

{:then data}
  <Modal isOpen={open} {toggle} size="xl">
    <ModalHeader {toggle}>Vyberte prosím variantu fotografie</ModalHeader>
    <ModalBody>
      <div class="confirmOnTop"><Button color="primary" outline on:click="{()=>addToCart()}">Potvrdit</Button></div>
      <div class="variantBoxes">
        {#each data.variant as vr, z}
          {#if vr['fs_varianta.skupinova']=="0" && selectedSkupinova=="0" || vr['fs_varianta.skupinova']=="1" && selectedSkupinova=="1"}
            <div class="variant-box">
              <Card style="width:350px;max-width:100%;">
                <div class="variant-box-card">
                  <CardHeader>
                    <b>{vr['fs_varianta.nazev']}</b>
                  </CardHeader>
                  <CardBody>
                    <p>{vr['fs_varianta.popis']}</p>
                    { newVariant(z,vr['id_fs_varianta'],data.cart)}
                    {#if parseInt(vr['fs_varianta.pocet'])==1}
                      <div class="txtcenter">
                        {#if selected[z]==1}
                          <Button color="danger" on:click="{()=>{setItem(data, z,0);addToCart()}}">Odebrat</Button>
                        {:else}
                          <Button color="primary" on:click="{()=>{setItem(data, z,1);addToCart()}}">
                            {#if vr.cena!=0}
                              Vybrat za cenu {vr.cena},-Kč
                            {:else}
                              Vybrat
                            {/if}
                          </Button>
                        {/if}
                      </div>
                    {:else}
                      <select bind:value={selected[z]}>
                        <option value="0">Vyberte počet...</option>
                        {#each Array(pocetCount(vr['fs_varianta.minimalni_pocet'], vr['fs_varianta.pocet'])) as _, i}
                          <option value="{(i+1)+pocetAdd(vr['fs_varianta.minimalni_pocet'])}">
                            {(i+1+pocetAdd(vr['fs_varianta.minimalni_pocet']))}ks, celková cena {getPrice(i, vr.cena, vr['fs_varianta.minimalni_pocet'])},-Kč
                          </option>
                        {/each}
                      </select>
                      {#if vr['fs_varianta.file']}
                        <br /><br />
                        <center><img src="{$path}{data.config.folderVariant}/{vr['fs_varianta.file']}" alt="Ukázka balíčku" nosave style="max-height:190px;" /></center>
                      {/if}
                    {/if}
                  </CardBody>
                </div>
              </Card>
            </div>
            {#if parseInt(vr['fs_varianta.pocet'])==1}
                <br style="clear:both" />
            {/if}
          {/if}
        {/each}
      </div>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click="{()=>addToCart()}">Potvrdit</Button>
      <Button color="secondary" on:click={()=>closeSelect()}>Zrušit</Button>
    </ModalFooter>
 </Modal>
{/await}

<style>
  .txtcenter { text-align: center; }
  .variantBoxes { flex-wrap:wrap; row-gap:5px; float:left; }
  .variant-box { width:300px; float:left; margin:0 10px 7px; display:flex; flex: 1 1 auto; }
  .variant-box-card { flex: 1 1 auto; }
  .confirmOnTop { float:right; position: relative; top:-66px; left:-32px; }
  @media (max-width:960px) {
    .confirmOnTop { display:none; }
  }
</style>
