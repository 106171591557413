<script lang="ts">
  import { Button, ButtonGroup } from 'sveltestrap';
  
  export let selected='';
  export let data;

 let paymentOptions = [
    {
      title: 'Hotově',
      value: 'hotove',
    },
    {
      title: 'Platební kartou - platební brána',
      value: 'platebniBrana',
    },
    {
      title: 'Převodem z účtu',
      value: 'prevod',
    },
  ];


  function isAllowedPayment(type, sett, conf) {
    if(conf=="prevodHotove" && (type=="hotove" || type=="prevod")) { conf=type; }
    else if(conf=="platebniBranaHotove" && (type=="hotove" || type=="platebniBrana")) { conf=type; }
    else if(conf=="platebniBranaPrevod" && (type=="platebniBrana" || type=="prevod")) { conf=type; }

    let value = true;
    if(sett==false) { value = false; }
    else if(conf=="") { value = true; }
    else if(conf!=type) { value = false; }
    if(selected=="" && value) { selected = type; }
    return value;
  }
  
  function selectedButton(val) {
  	selected=val;
  }
  
  function getTitle(confTitle, def) {
  	if(confTitle) { return confTitle; }
  	return def;
  }
</script>
              
{#await data}

{:then data}
 {#if data}
	<ButtonGroup vertical>
	  {#each paymentOptions as item}
	  	{#if
		    item.value=="hotove" && isAllowedPayment("hotove", data.config.payment.hotove, data.school.platba) ||
		    item.value=="platebniBrana" && isAllowedPayment("platebniBrana", data.config.payment.platebniBrana, data.school.platba) ||
		    item.value=="prevod" && isAllowedPayment("prevod", data.config.payment.prevod, data.school.platba)
		}
		  	{#if item.value==selected}
		  		<Button on:click="{()=>selectedButton(item.value)}" color="primary">{@html getTitle(data.config.text[item.value], item.title)}</Button>
		  	{:else}
		  		<Button on:click="{()=>selectedButton(item.value)}" color="light">{@html getTitle(data.config.text[item.value], item.title)}</Button>
		  	{/if}
	  	{/if}
	  {/each}
	</ButtonGroup>
 {/if}
{/await}
