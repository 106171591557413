<script>
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Alert
    } from 'sveltestrap';
  import Radio from '@smui/radio';
  import { Textfield, Checkbox } from 'svelte-mui';
	import { api_url, path, errorColor } from "../ConfigService.js";
  import PaymentSelect from "../components/PaymentSelect.svelte";
  import TransportSelect from "../components/TransportSelect.svelte";

  export let data;
  export let open=false;
  const toggle = () => (open = !open);

  let jmeno="";
  let prijmeni="";
  let telefon="";
  let email="";
  let adresa="";
  let mesto="";
  let psc="";
  let poznamka="";
  let gdpr=false;
  let error=false;
  export let totalPrice=0;
  export let success=false;
  export let addToCartCount=0;
  export let paymentSelect="";
  export let transportSelect="";

  function validateEmail(mail) {
        var re = /\S+@\S+\.\S+/;
        return re.test(mail);
  }

  function validateTelephone(tel) {
        var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,7}$/im;
        return re.test(tel);
  }

	async function confirmOrder() {
		if(jmeno=="" || prijmeni=="" || email=="" || !validateEmail(email) || telefon=="" || !validateTelephone(telefon) || !gdpr) {
			error=true;
		} else {
			const resp = await fetch($api_url+'?cart=true&confirm=true', {
				method: 'POST',
				body: JSON.stringify({
	        jmeno: jmeno,
          prijmeni: prijmeni,
					email: email,
					telefon: telefon,
          adresa: adresa,
          mesto: mesto,
          psc: psc,
          poznamka: poznamka,
          platba: paymentSelect,
          doprava: transportSelect
				})
			})
			open = false;
	    data = await resp.json();
      addToCartCount=0;
      if(await data) {
        if(paymentSelect=="platebniBrana") {
          window.location.href=$api_url+"payment/?id="+await data.order.id+"&price="+await data.order.price;
        } else {
          success=true;
        }
      }
		}
	}

  function printPrice(price, doprava, platba, d) {
    if(price>0) {
      if(d) {
        if(d.config) {
          if(doprava=="zasilkovna") {
            if(parseInt(d.config.delivery.zasilkovnazdarma)>price || d.config.delivery.kuryrzdarma=="-1") {
                if(platba=="hotove" && d.config.delivery.zasilkovnadobirka!="-1") { price+=parseInt(d.config.delivery.zasilkovnadobirka); }
                else { price+=parseInt(d.config.delivery.zasilkovnacena); }
            }
          } else if(doprava=="kuryr") {
            if(parseInt(d.config.delivery.kuryrzdarma)>price || d.config.delivery.kuryrzdarma=="-1") {
                if(platba=="hotove" && d.config.delivery.kuryrdobirka!="-1") { price+=parseInt(d.config.delivery.kuryrdobirka); }
                else { price+=parseInt(d.config.delivery.kuryrcena); }
            }
          }
        }
      }
      return " za "+price+",-Kč";
    }
    return "";
  }

  function closeConfirmDialog() { open=false; }
</script>

<Modal isOpen={open} {toggle} size="lg">
   <ModalHeader {toggle}>Dokončení objednávky</ModalHeader>
   <ModalBody>
     {#if error && !gdpr}
       <Alert color="danger">
         <br /><h4 class="alert-heading text-center">Musíte souhlasit se zpracováním osobních údajů dle GDPR!</h4><br>
       </Alert>
     {:else if error}
       <Alert color="danger">
         <br /><h4 class="alert-heading text-center">Vyplňte prosím správně všechna pole!</h4><br>
       </Alert>
     {/if}
     <div style="width:85%;margin:auto;">
      <table>
        <tr>
          <td><Textfield type="text" label="Jméno" name="jmeno" bind:value={jmeno} required></Textfield></td>
          <td><Textfield type="text" label="Příjmení" name="prijmeni" bind:value={prijmeni} required></Textfield></td>
        </tr>
        <tr>
          <td><Textfield type="email" label="E-mail" name="email" bind:value={email} required></Textfield></td>
          <td><Textfield type="tel" label="Telefon" name="telefon" bind:value={telefon} required></Textfield></td>
        </tr>
        {#if typeof data === 'object'}
          {#if data.config.billingAddress && transportSelect!="osobne"}
            <tr>
              <td colspan="2"><Textfield type="text" label="Adresa" name="adresa" bind:value={adresa} required></Textfield></td>
            </tr>
            <tr>
              <td><Textfield type="number" label="PSČ" name="psc" bind:value={psc} required min="11000" max="79376"></Textfield></td>
              <td><Textfield type="text" label="Město" name="mesto" bind:value={mesto} required></Textfield></td>
            </tr>
          {/if}
        {/if}
        </table>
        <table>
        {#if typeof data === 'object'}
          {#if data.config.note}
            <tr>
              <td colspan="2"><Textfield type="text" label="Poznámka" name="poznamka" bind:value={poznamka}></Textfield></td>
            </tr>
          {/if}
          {#if data.config.payment.prevod || data.config.payment.platebniBrana}
            <tr>
              <td style="text-align:right;vertical-align:top;padding-top:22px;min-width:140px;"><b>Způsob platby:</b></td>
              <td style="padding-top:18px;"><PaymentSelect data={data} bind:selected={paymentSelect} /></td>
            </tr>
          {/if}
          {#if data.config.delivery}
            <tr>
              <td style="text-align:right;vertical-align:top;padding-top:22px;min-width:140px;"><b>Způsob odběru:</b></td>
              <td style="padding-top:18px;"><TransportSelect data={data} bind:selected={transportSelect} /></td>
            </tr>
          {/if}
        {/if}
       </table>
       <br>
        <div style="float:left;width:32px;"><Checkbox bind:checked={gdpr}></Checkbox></div>
        <span style="float:left;max-width:610px;padding-top:10px;">
          {#if typeof data === 'object'}
            {#if data.config.text.gdpr}
              {@html data.config.text.gdpr}
            {:else}
              Souhlasím se zpracováním osobních údajů dle GDPR
            {/if}
            {:else}
              Souhlasím se zpracováním osobních údajů dle GDPR
          {/if}
        </span>
     </div>
   </ModalBody>
   <ModalFooter>
     <Button color="primary" on:click="{()=>confirmOrder()}">
      {#if paymentSelect=="platebniBrana"}
        Zaplatit objednávku {printPrice(totalPrice, transportSelect, paymentSelect, data)}
      {:else}
        Závazně objednat  {printPrice(totalPrice, transportSelect, paymentSelect, data)}
      {/if}
     </Button>
     <Button color="secondary" on:click={()=>closeConfirmDialog()}>Zrušit</Button>
   </ModalFooter>
</Modal>

<style>
table { width:100%; }
td { padding-right:15px; }
</style>
