<script>
	import { Router, Route, Link } from "svelte-routing";
	import {
		Button,
		ButtonGroup,
		Modal,
		ModalBody,
		ModalFooter,
		ModalHeader,
		Icon,
		Input,
		Popover,
		Tooltip,
		Card,
		CardBody,
		CardFooter,
		CardHeader,
		CardSubtitle,
		CardText,
    		CardTitle } from 'sveltestrap';
	import { api_url, path } from "../ConfigService.js";
	import Loading from "../components/Loading.svelte";
	import ImgDialog from "../components/imgDialog.svelte";
	import SelectVariantDialog from "../components/selectVariantDialog.svelte";
	import SelectVariantsDialog from "../components/selectVariantsDialog.svelte";
	import MediaQuery from "../components/MediaQuery.svelte";
	import MenuItem from "../components/menuItem.svelte";

	export let id;
	export let key;
	export let selectedFile=false;
	export let selectedFileId=false;
	export let selectedSkupinova="0";
	export let countOfProduct=0;
	export let addToCartCount=0;
	export let img;
	export let checkboxes=[];
	export let selectedData=false;
	let findImg="";
	let smallMode="middle";
	export let downloadApi;
	export let downloadApiData;

	async function getDownloadApiData() {
		const resp = await fetch(downloadApi);
    		downloadApiData = await resp.json();
	}

	export let data = (async () => {
		 const resp = await fetch($api_url+'?album='+id+'&key='+key);
		 if(downloadApi) { getDownloadApiData(); }
		 return await resp.json()
	})()

	function selectVariant(f, i, sk) {
		selectedFile=f;
		selectedFileId=i;
		selectedSkupinova=sk;
	}

	function selectVariants(d) {
		selectedData=d;
	}

	function removeSuffix(name) {
			name=name.replaceAll(".jpg", "");
			name=name.replaceAll(".JPG", "");
			return name;
	}

	function inCartColor(d, f) {
		if(d.cart) {
			for(let i=0; i<d.cart.length; i++) {
				if(d.cart[i].file==f) {
					return "success";
				}
			}
		}
		return "primary";
	}

	function inCartText(d, f) {
		if(d.cart) {
			for(let i=0; i<d.cart.length; i++) {
				if(d.cart[i].file==f) {
					return "Upravit výběr";
				}
			}
		}
		return "Vybrat variantu";
	}

	function onRightClick(e) { return false; }

	function activeMode(sm, thisMode) {
		if(thisMode==sm) { return " buttonActive"; }
		return "";
	}

	function changeMode(value) {
		smallMode=value;
		const res = fetch($api_url+'?changeMode=true&smallMode='+value);
		return "";
	}

	function imgPreview(f) { img=f; }

	function isSearchedImg(img, text) {
		if(img=="") { return true; }
		if(img.includes(text)) { return true; }
		return false;
	}

	function isLeastOneSelected(ch) {
		for(let i=0; i<ch.length; i++) {
			if(ch[i]) { return true; }
		}
		return false;
	}

	function isSelected(ch, d, f) {
		if(ch) { return " selectedBox"; }
		else if(inCartColor(d, f)!="primary") { return " inCartBox"; }
		return "";
	}

	function isBig(sm) {
			if(sm=="big") { return " img-box-big"; }
			return "";
	}
	
	 function isPhotoDownloadable(i) {
		if(downloadApi) {
			if(downloadApiData) {
				if(downloadApiData['files']) { 
					if(downloadApiData['files'].includes(i)) { return true; }
				}
			}
		}
		return false;
	}

	$: { if(data) { if(data.countOfProduct) { countOfProduct=data.countOfProduct; } } }

</script>
{#await data}
	<Loading />
{:then data}
	<br /><br />
	{#if data.album !== 'null'}
		<h2 class="photoHeader">{data.album[0].nazev}</h2>
	{:else}
		<h2 class="photoHeader">{data.school.nazev}</h2>
	{/if}
	{#if data.config.smallMode}
		{changeMode(data.config.smallMode)}
	{/if}
	<div class="buttons">
		{#if data.config.photoBrowseMode=="3"}
			<span class="button{activeMode(smallMode, "big")}" on:click="{()=>changeMode("big")}" id="bigModeBtn"><Icon name="grid-1x2-fill" /></span>
			<Tooltip target="bigModeBtn" placement="bottom">Velké náhledy fotografií</Tooltip>
		{/if}
		<span class="button{activeMode(smallMode, "middle")}" on:click="{()=>changeMode("middle")}" id="middleModeBtn"><Icon name="grid-fill" /></span>
		<Tooltip target="middleModeBtn" placement="bottom">Střední náhledy fotografií</Tooltip>
		{#if data.config.photoBrowseMode=="2" || data.config.photoBrowseMode=="3"}
			<span class="button{activeMode(smallMode, "small")}" on:click="{()=>changeMode("small")}" id="smallModeBtn"><Icon name="grid-3x3-gap-fill" /></span>
			<Tooltip target="smallModeBtn" placement="bottom">Malé náhledy fotografií</Tooltip>
		{/if}
		&nbsp;
	</div>
	<div class="searchBar">
	{#if isLeastOneSelected(checkboxes) && data.variant.length>0}
		<Button color="primary" on:click="{()=>selectVariants(data.foto)}" size="sm">Vybrat variantu pro označené fotografie</Button>
	{:else}
		<MediaQuery query="(max-width: 440px)" let:matches>
			{#if matches}
				<br /><br />
			{/if}
		</MediaQuery>
		{#if data.variant.length>0}
			<Button color="secondary" size="sm" id="noClickSet" style="cursor:default;">Vybrat variantu pro označené fotografie</Button>
		{/if}
		<MediaQuery query="(max-width: 440px)" let:matches>
		  {#if matches}
				<Popover target="noClickSet" placement="bottom">Nejprve prosím vyberte fotografie...</Popover>
			{:else}
				<Popover target="noClickSet" placement="right">Nejprve prosím vyberte fotografie...</Popover>
			{/if}
		</MediaQuery>
	{/if}
	</div>
	{#if smallMode=="small"}
		<MediaQuery query="(min-width: 441px)" let:matches>
			{#if matches}
				<div class="searchBar mgauto psrelminus">
					<Input type="text" name="search" placeholder="najít fotografii dle názvu" bind:value={findImg} />
				</div>
			{/if}
		</MediaQuery>
		<br />
		<div class="img-boxes mgt">
		{#each data.foto as ph, z}
			{#if isSearchedImg(ph.file, findImg)}
				<div class="img-box-small{isSelected(checkboxes[z], data, ph.file)}">
					<Card body>
						<div>
							<div class="left">
								<span on:click="{()=>imgPreview($path+data.config.folderPhoto+"/"+ph.id+".jpg")}">
									<img src="{$path}{data.config.folderPhotoPreview}/{ph.id}.jpg" width="{ph.width}" height="{ph.height}" alt="" loading="lazy" nosave on:contextmenu|preventDefault={onRightClick} class="click" />
								</span>
							</div>
							<div class="left">
								<i><label>{removeSuffix(ph.file)}<br />
								{#if ph.skupinova!="1"}
									&nbsp;<input type="checkbox" bind:checked="{checkboxes[z]}" />
								{/if}
								</label></i></div>
							{#if data.variant.length>0}
								<div class="right"><Button color="{inCartColor(data, ph.file)}" on:click="{()=>selectVariant(ph.file, ph.id, ph.skupinova)}" size="sm">Vybrat ...</Button></div>
							{/if}
						</div>
					</Card>
				</div>
			{/if}
		{/each}
		</div>
		<br style="clear:both" />
		<br />
	{:else}
		<br />
		<div class="img-boxes mgt">
		{#each data.foto as ph, z}
			<div class="img-box{isSelected(checkboxes[z], data, ph.file)}{isBig(smallMode)}">
				<Card body>
					<img src="{$path}{data.config.folderPhoto}/{ph.id}.jpg" width="{ph.width}" height="{ph.height}" alt="" loading="lazy" nosave on:contextmenu|preventDefault={onRightClick} />
					<label>
						{#if ph.skupinova!="1"}
							&nbsp;<input type="checkbox" bind:checked="{checkboxes[z]}" />&nbsp;
						{/if}
						&nbsp;<i>Označení: {removeSuffix(ph.file)}</i></label><br />
						{#if data.variant.length>0}
							<div class="mgt mgauto"><Button color="{inCartColor(data, ph.file)}" on:click="{()=>selectVariant(ph.file, ph.id, ph.skupinova)}">{inCartText(data, ph.file)} ...</Button></div>
						{/if}
						{#if downloadApiData}
							{#if isPhotoDownloadable(ph.file)}
								<div class="downloadIcon"><a href="{downloadApi}&download={ph.file}" download><Icon name="download" /></a></div>
							{/if}
						{/if}
				</Card>
			</div>
		{/each}
		</div>
	{/if}
	{#if addToCartCount>0}
		<div class="button cartButton" id="cartBtn">
			<MenuItem to="cart/" icon="cart-fill"></MenuItem>
			<Tooltip target="cartBtn" placement="left">Přejít do nákupního košíku</Tooltip>
		</div>
	{/if}
{/await}
  <ImgDialog bind:img />
	<SelectVariantDialog bind:data bind:selectedFile bind:selectedFileId bind:selectedSkupinova bind:id bind:key bind:addToCartCount />
	<SelectVariantsDialog bind:data bind:selectedData bind:checkboxes bind:id bind:key bind:addToCartCount />

<style>
	.photoHeader { position:relative; top:-30px; }
	.searchBar { float:left; width:300px; }
	.buttons { float:right; text-align:right; font-size:25px; }
	.button { border:1px solid rgba(0,0,0,.125); border-radius: calc(.25rem - 1px); padding:5px; cursor: pointer; }
	.buttonActive { background-color:rgba(0,0,0,.1); cursor:auto; }
	.mgt { margin-top:15px; }
	.psrelminus { position:relative; top:-25px; }
	.img-boxes { display: flex; align-items: stretch; justify-content: space-around; flex-wrap:wrap; }
	.img-box { /*max-width:500px;*/ float:left; margin:auto 10px 7px; border:1px solid transparent; border-radius: 5px; }
	.img-box img { margin:auto; width:auto; height:auto; max-width:70vw; max-height:310px; }
	.img-box-big img { max-width:99vw; max-height:99vh; }
	.left { float:left; width:100px; }
  .right { float:right; width:80px; }
	.mgauto { margin:auto; }
	.click { cursor: pointer; }
	.img-box-small { width:260px; float:left; margin:auto 6px 7px; /*height:115px;*/ border:1px solid transparent; border-radius: 5px; }
	.img-box-small img { float:left; width:auto; height:auto; max-width:95px; max-height:80px; }
	.downloadIcon { text-align:right; font-size:28px; }
	.selectedBox { border:1px solid red; border-radius: 5px; }
	.inCartBox { border:1px solid green; border-radius: 5px; }
	.cartButton { position:fixed; bottom:10px; right:20px; padding-left:10px; background-color:white; font-size:20px; }
	input[type=checkbox] { transform: scale(1.4); }
</style>
