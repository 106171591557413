<script>
  import { Link } from "svelte-routing";
  export let to="/";
</script>

{#if to=="#"}
  <a href="#" onclick="return false"><slot /></a>
{:else}
  <Link to={to}><slot /></Link>
{/if}
