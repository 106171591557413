<script>
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Alert
    } from 'sveltestrap';

  export let data;
  export let open=false;
  export let id;
  export let key;
  const toggle = () => (open = !open);

  function closeDialog() { open=false; }
</script>

<Modal isOpen={open} {toggle}>
   <ModalHeader {toggle}>Sdílet</ModalHeader>
   <ModalBody>
    {#await data}
    {:then data}
     <center>
       <!--<img src="https://chart.googleapis.com/chart?cht=qr&chl={encodeURIComponent(data.url+'/key/'+data.kod+'/')}&chs=200x200&choe=UTF-8" />-->
       <img src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&data={encodeURIComponent(data.url+'/key/'+data.kod+'/')}" />-
       <br><br>
       {data.url}/key/{data.kod}/
     </center>
    {/await}
   </ModalBody>
   <ModalFooter>
     <Button color="secondary" on:click={()=>closeDialog()}>Zavřít</Button>
   </ModalFooter>
</Modal>

<style>

</style>
