<script>
	import { onMount } from 'svelte';
	import { api_url, errorColor } from "../ConfigService.js";
	import Loading from "../components/Loading.svelte";
	import { Button, Textfield } from 'svelte-mui';

	export let code="";
	export let data;
	export let error;

	async function login() {
		const res = await fetch($api_url+'', {
			method: 'POST',
			body: JSON.stringify({
       			code: code
			})
		})
    		data = await res.json();
		if(await data && !await data.kod) { error="Špatně zadaný přístupový kód!"; }
	}

	const handleSubmit = () => {
		login();
	}

	onMount(async () => {
		let url = window.location.href.split("/");
		if(url[3]=="key" && url[4] && url[4]!="") {
			 code=url[4];
			 login();
		}
	});
</script>
{#await data}
	<Loading />
{:then data}
				{#if !data.kod && error}
					<span style="color:{$errorColor}">{error}</span>
				{:else}
					
				{/if}

			{#if data.text && data.text.length>0}
				{#if data.text[0].data.text}
					<p>{@html data.text[0].data.text}</p>
				{/if}
			{/if}
			<br>
				<form on:submit|preventDefault={handleSubmit}>
					<Textfield type="text" label="Zadejte přístupový kód" name="code" bind:value={code} bind:error required class="codeInput" />
					<Button outlined on:click={login}>Vstoupit</Button>
				</form>

{/await}

<style>

</style>
