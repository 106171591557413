<script>
  import { link } from "svelte-routing";
  //import MenuItem from "../components/menuItem.svelte";
  import { api_url } from "../ConfigService.js";

  export let data;
  let navbarOpen = false;
  export let addToCartCount=0;
  export let success=false;
  export let isOrder;

  async function logout() {
    const res = await fetch($api_url+'?logout=true')
    data = await res.json();
    return false;
  }

  function setNavbarOpen() {
    navbarOpen = !navbarOpen;
  }

	function countOfProductInCart(countOfProduct, cartCount) {
		if(cartCount!=0 && cartCount != null && cartCount != "NaN") {
			return " ("+cartCount+")";
		}
		else if(!(typeof countOfProduct == "undefined" || countOfProduct == null) && Array.isArray(countOfProduct)) {
			if(countOfProduct.length>0 && !success) {
				addToCartCount=countOfProduct.length;
				return " ("+countOfProduct.length+")";
			}
		}
		return "";
	}
</script>

<nav
  class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
>
  <div
    class="container masterBox px-4 mx-auto flex flex-wrap items-center justify-between"
  >
    <div
      class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
    >
      <a
        use:link
        class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
        href="/"
        on:click="{()=>isOrder=false}"
      >
        <img src="/logo.png" alt="" width="300" />
      </a>
    </div>
  {#if data.logged}
   {#await data}
   {:then data}
    <div
      class="lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none rounded {navbarOpen ? 'block':'hidden'}"
      id="example-navbar-warning"
    >
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
<!--
      <MenuItem to="/" icon="house-door-fill">Úvodní nabídka</MenuItem>&nbsp;&nbsp;|&nbsp;&nbsp;
      <MenuItem to="cart/" icon="cart-fill">Nákupní košík{countOfProductInCart(data.cart, addToCartCount)}</MenuItem>&nbsp;&nbsp;|&nbsp;&nbsp;
-->
        <li class="flex items-center">
          <a use:link href="/"><button
            class="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button"
            use:link href="/"
          ><i class="fas fa-home"></i> Úvodní strana</button></a>
          <a use:link href="/cart/"><button
            class="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button"
          ><i class="fas fa-shopping-cart"></i> Nákupní košík{countOfProductInCart(data.cart, addToCartCount)}</button></a>

          <!--<a use:link href="/" icon="house-door-fill">Úvodní nabídka</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a use:link href="/cart/" icon="cart-fill">Nákupní košík{countOfProductInCart(data.cart, addToCartCount)}</a>&nbsp;&nbsp;|&nbsp;&nbsp;-->
          <button
            class="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button"
            on:click="{()=>logout()}"
          >
            <i class="fas fa-times"></i> Odhlásit se
          </button>
        </li>
      </ul>
    </div>
   {/await}
  {/if}
  </div>
</nav>

<style>
  .masterBox { min-width:80%; max-width:1600px; }
</style>
