<script>
	import { Router, Route, Link } from "svelte-routing";
	import { Styles,
		Button,
	 	Table,
		Icon,
		Alert,
		Tooltip} from 'sveltestrap';
	import { api_url, path, errorColor } from "../ConfigService.js";
	import Loading from "../components/Loading.svelte";
	import ImgDialog from "../components/imgDialog.svelte";
	import DeleteDialog from "../components/deleteDialog.svelte";
	import ConfirmDialog from "../components/confirmDialog.svelte";
	import MediaQuery from "../components/MediaQuery.svelte";
	import { Textfield } from 'svelte-mui/src';

	export let id;
	export let img;
	export let openConfirmDialog=false;
	export let selectedFile=false;
	export let selectedType=false;
	export let countOfProduct=0;
	export let success=false;
	export let status=false;
	export let addToCartCount=0;
	let totalPrice=0;

	let txtOrderSuccess="Objednávka byla úspěšně odeslána!";
	let txtOrderSuccessNote="Zkontrolujte si prosím správnost objednávky ve vašem e-mailu.";
	let txtOrderError="Při platbě došlo k chybě!";
	let txtOrderErrorNote="";
	let txtCartNote="";

	export let data = (async () => {
		 const resp = await fetch($api_url+'?cart=true')
		 return await resp.json()
	})()

	function removeSuffix(nm) {
			if(nm && nm!=null) {
				nm=nm.replaceAll(".jpg", "");
				nm=nm.replaceAll(".JPG", "");
				return nm;
			}
			return "";
	}

	function countPrice(p, z) {
		if(z==0) { totalPrice=0; }
		totalPrice+=parseInt(p);
		return p;
	}
	function completeDelivery() { openConfirmDialog=true; }
	function deleteImg(f, t) { selectedType=t; selectedFile=f; }
	function imgPreview(f) { img=f; }
	function getPrice(i, cena) { return ((i+1)*parseInt(cena)); }

	function removeHTMLChar(txt) { return txt.replace(/(<([^>]+)>)/gi, ""); }

  function loadTexts(txt) {
		if(txt.txtOrderSuccess) { txtOrderSuccess=txt.txtOrderSuccess; }
		if(txt.txtOrderSuccessNote) { txtOrderSuccessNote=txt.txtOrderSuccessNote; }
		if(txt.txtOrderError) { txtOrderError=txt.txtOrderError; }
	  if(txt.txtOrderErrorNote) { txtOrderErrorNote=txt.txtOrderErrorNote; }
	  if(txt.txtCartNote) { txtCartNote=txt.txtCartNote; }
	}

	$: {
			if(data) {
				if(data.countOfProduct) { countOfProduct=data.countOfProduct; }
				if(data.config) { if(data.config.text) { loadTexts(data.config.text); } }
			}
		}

</script>
{#await data}
	<Loading />
{:then data}
	<Table hover style="width:85%; max-width:800px; margin:auto;">
	<thead>
		<tr>
			<th>Fotografie</th>
			<th>Typ</th>
			<th>Počet</th>
			<th>Cena</th>
			<th>
				<MediaQuery query="(min-width: 441px)" let:matches>
					{#if matches}
						Odstranit z košíku
					{/if}
				</MediaQuery>
			</th>
		</tr>
	</thead>
	<tbody>
		{#if data.cart && !success}
			{#each data.cart as ph, z}
				<tr>
					<td>
						<span class="link" id="img-{z}" on:click="{()=>imgPreview($path+data.config.folderPhoto+"/"+ph.id+".jpg")}">{removeSuffix(ph.file)}</span>
						<MediaQuery query="(min-width: 441px)" let:matches>
							{#if matches}
								<Tooltip target="img-{z}" placement="right"><img src="{$path+data.config.folderPhotoPreview+"/"+ph.id+".jpg"}" loading="lazy" alt="Náhled fotografie" /></Tooltip>
							{/if}
						</MediaQuery>
					</td>
					<td>
						{#each data.variant as va}
							{#if ph.type==va.id_fs_varianta}
								<span id="var-{va['id']}-{ph.id}">{va['fs_varianta.nazev']}</span>
								{#if va['fs_varianta.popis']}
									<Tooltip target="var-{va['id']}-{ph.id}" placement="right">
										{removeHTMLChar(va['fs_varianta.popis'])}
									</Tooltip>
								{/if}
							{/if}
						{/each}
					</td>
					<td>{ph.count}</td>
					<td>{countPrice(ph.price, z)},-Kč</td>
					<td class="iconButton">
						<span on:click="{()=>deleteImg(ph.id, ph.type)}" id="del-{z}" title="Odstranit z košíku" class="click"><Icon name="x-circle-fill" /></span>
						<Tooltip target="del-{z}" placement="right">Odstranit z košíku</Tooltip>
					</td>
				</tr>
			{/each}
		{/if}
		</tbody>
		</Table>

		{#if status=="error"}
			<Alert color="danger">
				<br><h4 class="alert-heading text-center">{txtOrderError}</h4><br>
				<p class="txtcenter">{txtOrderErrorNote}</p>
			</Alert>
		{:else if success || status=="success"}
			<Alert color="success">
				<br><h4 class="alert-heading text-center">{txtOrderSuccess}</h4><br>
				<p class="txtcenter">{txtOrderSuccessNote}</p>
			</Alert>
		{:else if data.cart}
			<p><b>Celková cena: {totalPrice},-Kč</b></p><br>
			{#if txtCartNote}
				<br><br><p>{txtCartNote}</p><br>
			{/if}
			<Button color="primary" on:click={()=>completeDelivery()} style="margin:auto">Závazně potvrdit objednávku</Button>
			<br><br>
		{:else}
			<Alert color="danger">
				<br><h4 class="alert-heading text-center">V košíku nemáte žádné položky!</h4><br>
			</Alert>
		{/if}
		<ImgDialog bind:img />
		<ConfirmDialog bind:open="{openConfirmDialog}" data={data} bind:totalPrice bind:addToCartCount bind:success />
{/await}
	<DeleteDialog bind:data bind:selectedFile bind:selectedType bind:addToCartCount />

<style>
	.img-box { min-width:300px; max-width:500px; float:left; margin:0 10px 7px; }
	.img-box img { max-width:490px; }
	.variant-box { width:300px; float:left; margin:0 10px 7px; }
	.iconButton { text-align:center; margin:auto; font-size:20px; }
	.iconButton i { width:40px; margin:auto; }
	.link { border-bottom:1px black dashed; cursor:pointer; }
	.click { cursor:pointer; }
	tr:nth-child(2n) { background-color: rgba(0,0,0,.03); }
	.txtcenter { text-align: center; }
</style>
