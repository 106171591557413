<script>
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Alert
    } from 'sveltestrap';
	import { api_url, path, errorColor } from "../ConfigService.js";

  export let data;
  export let selectedFile;
  export let selectedType;
  export let addToCartCount=0;
  let open = false;
  const toggle = () => (selectedFile = !selectedFile);

  $: {
    if(selectedFile) { open=true; }
    else { open=false; }
  }

	async function removeFromCart() {
  		const resp = await fetch($api_url+'?cart=true&removeFromCart='+selectedFile+'&removeType='+selectedType)
  		open = false;
      data = await resp.json();
      selectedFile=false;
      selectedType=false;
      addToCartCount--;
      if(addToCartCount<0) { addToCartCount=0; }
  	}

  function closeDeleteDialog() { selectedFile=false; selectedType=false; open=false; }
</script>

<Modal isOpen={open} {toggle}>
    <ModalHeader {toggle}>Odstranění z košíku</ModalHeader>
    <ModalBody>
      Opravdu chcete fotografii odstranit z košíku?
    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click="{()=>removeFromCart()}">Odstranit</Button>
      <Button color="secondary" on:click={()=>closeDeleteDialog()}>Zrušit</Button>
    </ModalFooter>
 </Modal>
