<script>
  import { bgColor } from "../ConfigService.js";
  import { Firework } from 'svelte-loading-spinners'

  export let visible=true;

  let color="red";
  $: {
    if($bgColor) { color=$bgColor; }
  }
</script>

{#if visible}
  <center><div class="loading"><Firework size="80" color={color} unit="px" duration="1s"></Firework></div></center>
{/if}
