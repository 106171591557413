<script>
	import { Router, Route, Link } from "svelte-routing";
	import { Styles,
		Button,
	 	Table,
		Icon,
		Alert,
		Tooltip} from 'sveltestrap';
	import { api_url, path, errorColor } from "../ConfigService.js";
	import Loading from "../components/Loading.svelte";
	
	export let zip="";
	export let auth;
	export let id;
	export let params="?id="+id+"&auth="+auth;

	export let data = (async () => {
		const resp = await fetch($api_url+"order/"+params);
		return await resp.json()
	})()

	function removeSuffix(nm) {
			if(nm && nm!=null) {
				nm=nm.replaceAll(".jpg", "");
				nm=nm.replaceAll(".JPG", "");
				return nm;
			}
			return "";
	}
	
	function addToZip(file) {
		zip+="&files[]="+file;
		return "";
	}

</script>
{#await data}
	<Loading />
{:then data}
	<b>Děkujeme za Vaší objednávku. Níže si můžete stáhnout zakoupené fotografie.</b><br /><br />
	<Table hover style="width:85%; max-width:800px; margin:auto;">
	<thead>
		<tr>
			<th>Fotografie</th>
			<th>Stažení</th>
		</tr>
	</thead>
	<tbody>
		{#if data.photo}
			{#each data.photo as p, z}
				<tr>
					<td>
						{removeSuffix(p['fs_foto.file'])}
					</td>
					<td>	
						{#if p['exists']=="1"}
							<a href="{$api_url}order/download/{params}&file={p['fs_foto.file']}" download>stáhnout</a>
							{addToZip(p['fs_foto.file'])}
						{:else}	
							připravujeme	
						{/if}
					</td>
				</tr>
			{/each}
		{/if}
		</tbody>
		</Table>
		<br />
		{#if zip}
			<a href="{$api_url}order/zip/{params}{zip}" download><Button color="primary">Stáhnout vše jako ZIP</Button></a>
		{/if}

{/await}


<style>
	.img-box { min-width:300px; max-width:500px; float:left; margin:0 10px 7px; }
	.img-box img { max-width:490px; }
	.variant-box { width:300px; float:left; margin:0 10px 7px; }
	.iconButton { text-align:center; margin:auto; font-size:20px; }
	.iconButton i { width:40px; margin:auto; }
	.link { border-bottom:1px black dashed; cursor:pointer; }
	.click { cursor:pointer; }
	tr:nth-child(2n) { background-color: rgba(0,0,0,.03); }
	.txtcenter { text-align: center; }
</style>
