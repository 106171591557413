<script>
	import { Router, Route, Link } from "svelte-routing";
	import { Styles,
		Icon,
		Button,
		Alert,
		Card, CardBody,
		CardFooter,
		CardHeader,
		CardSubtitle,
		CardText,
		CardTitle,
		Tooltip } from 'sveltestrap';
	import { api_url } from "../ConfigService.js";
	//import MenuItem from "../components/menuItem.svelte";
	import Loading from "../components/Loading.svelte";
	import Photo from "../pages/Photo.svelte";
	import Cart from "../pages/Cart.svelte";
  	import Article from "../components/article.svelte";
	import ShareDialog from "../components/shareDialog.svelte";

	export let code="";
	export let data;
	export let url="";
	export let addToCartCount=0;
	export let success=false;
	export let share=false;
	export let downloadApi;

	async function login() {
		const res = await fetch($api_url+'', {
			method: 'POST',
			body: JSON.stringify({
        		code: code
			})
		})
    		data = await res.json();
	}

	async function logout() {
		const res = await fetch($api_url+'?logout=true')
    		data = await res.json();
		return false;
	}

	function redirectToMain() {
		location.href="/";
		return "";
	}

	function shareDialog() {
		share=true;
	}
</script>
<Router url="{url}">
{#await data}
	<Loading />
{:then data}
{#if data.logged}
		{#if data.config.share}
			<div class="buttons">
				<span class="button" on:click="{()=>shareDialog()}" id="shareBtn"><Icon name="share-fill" /></span>
				<Tooltip target="shareBtn" placement="bottom">Sdílet galerii</Tooltip>
				&nbsp;
			</div>
		{/if}
			<Route path="/">
					<CardText>
						{#if data.text && data.text.length>0}
							{#if data.text[0].data.text}
								<p>{@html data.text[0].data.text}</p>
							{/if}
						{/if}
						{@html data.school.uvodni_info}
						{#if data.uzaverka}
							<br /><br />
							<Alert color="info">
								<h6 class="alert-heading text-center">Nejbližší uzávěrka objednávek: {data.uzaverka}</h6>
							</Alert>
						{/if}
					</CardText>
					<br>
			</Route>
			<Route path="key/:key" let:params>
				<Loading />
				{redirectToMain()}
			</Route>
			<Route path="album/:id" let:params>
				<Photo id={params.id} key="" bind:downloadApi bind:addToCartCount />	<!--  bind:addToCartCount -->
			</Route>
			<Route path="article/:id" let:params>
				{#if data.text && params.id}
					<Article title={data.text[params.id].data.title} text={data.text[params.id].data.text} />
				{/if}
			</Route>
			<Route path="album/:id/:key" let:params>
				<Photo id={params.id} key={params.key} bind:downloadApi bind:addToCartCount />	<!--  bind:addToCartCount -->
			</Route>
			<Route path="cart/:status" let:params>
				<Cart bind:addToCartCount status={params.status} />
			</Route>
			<Route path="cart/">
				<Cart bind:addToCartCount />
			</Route>
{/if}
{/await}
</Router>
<ShareDialog bind:data bind:open={share} />

<style>
	.classButton { margin-bottom:20px; }
	.navLink {text-align: right;}
	.navLink:hover, .navLink a:hover {text-decoration: none;}
	.navLink i { padding-right:10px; }
	.buttons { float:right; text-align:right; font-size:25px; }
	.button { border:1px solid rgba(0,0,0,.125); border-radius: calc(.25rem - 1px); padding:5px; cursor: pointer; }
</style>
