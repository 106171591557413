<script>
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
    } from 'sveltestrap';

  export let img=false;
  let open=false;
  const toggle = () => (img = !img);

  function onRightClick(e) { return false; }

  $: {
    if(img) { open=true; }
    else { open=false; }
  }
</script>

<Modal isOpen={open} toggle={toggle} scrollable size="lg">
  <ModalHeader toggle={toggle}>Náhled fotografie</ModalHeader>
  <ModalBody>
    <center><img src="{img}" style="max-width:600px;max-width:99%;max-height:80vh;" alt="fotografie" nosave on:contextmenu|preventDefault={onRightClick} /></center>
  </ModalBody>
</Modal>
